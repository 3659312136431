import { ReactElement } from "react";
import { Link } from "react-router-dom";
import { Zap } from "react-feather";

import "./ChatMessage.scss";

const formatDate = (d: Date) => {
  const nth = (d: number) => {
    if (d > 3 && d < 21) return 'th';
    switch (d % 10) {
      case 1:  return "st";
      case 2:  return "nd";
      case 3:  return "rd";
      default: return "th";
    }
  };
  let months = 'Jan Feb Mar Apr May Jun Jul Aug Sep Oct Nov Dec'.split(' ');
  let month = months[d.getMonth()];
  let day = d.getDate();
  let h = d.getHours();
  let p = h >= 12 ? 'PM' : 'AM';
  if (h > 12) {
    h = h % 12;
  }
  let m = d.getMinutes() + '';
  if (m.length === 1) {
    m = `0${m}`;
  }
  return `${month} ${day}${nth(day)} at ${h}:${m} ${p}`;
};

export interface FunctionResult {
  package: {
    name: string,
    version: string,
    url: string
  },
  filename: string,
  arguments: string,
  method: string,
  microcredits: number,
  name: string,
  result: string,
  url: string
}

export interface ChatMessageEntry {
  name?: string,
  avatar?: string,
  time?: Date | string,
  app?: boolean,
  message?: string,
  functions?: FunctionResult[]
  notification?: string
};

export default function ChatMessage({
  name = "User",
  avatar,
  time,
  app = false,
  message = "",
  functions = [],
  notification = ""
} : ChatMessageEntry) {
  time = new Date(time || Date.now());

  const messageParts: ReactElement[] = message
    .split(/(^|\s)(https?\:\/\/[^\s]+)/gi)
    .map((text, i) => {
      if (text.startsWith('http://') || text.startsWith('https://')) {
        if (text.endsWith('.')) {
          return <span key={i}><a href={text.slice(0, -1)} target="_blank">{text.slice(0, -1)}</a>.</span>;
        } else {
          return <span key={i}><a href={text} target="_blank">{text}</a></span>;
        }
      } else {
        return <span key={i}>{text}</span>;
      }
    });

  const notificationParts: ReactElement[] = notification
    .split(/(^|\s)(https?\:\/\/[^\s]+)/gi)
    .map((text, i) => {
      if (text.startsWith('http://') || text.startsWith('https://')) {
        if (text.endsWith('.')) {
          return <span key={i}><a href={text.slice(0, -1)} target="_blank">{text.slice(0, -1)}</a>.</span>;
        } else {
          return <span key={i}><a href={text} target="_blank">{text}</a></span>;
        }
      } else {
        return <span key={i}>{text}</span>;
      }
    });

  return (
    <div data-component="ChatMessage">
      <div className="chat-heading hidden"></div>
      <div className="chat-body">
        {(
          avatar
            ? <div className="chat-avatar image">
                <img src={avatar} />
              </div>
            : <div className={app ? `chat-avatar app` : `chat-avatar`}>
                {(name[0] || "A").toUpperCase()}
              </div>
        )}
        <div className="chat-content">
          <div className="chat-author">
            <div className="chat-author-name">{name}{app && <span className="app">APP</span>}</div>
            <div className="chat-time">{formatDate(time)}</div>
          </div>
          <div className="chat-message">
            {messageParts}
            {notification && <div className="chat-notification">{notificationParts}</div>}
          </div>
          {!!functions.length && (
            <div className="functions">
              {functions.map((f, i) => {
                let args = f.arguments;
                let result = f.result;
                try {
                  args = JSON.stringify(JSON.parse(args), null, 2);
                } catch (e) {
                  // do nothing
                }
                try {
                  result = JSON.stringify(JSON.parse(result), null, 2);
                } catch (e) {
                  // do nothing
                }
                return (
                  <div className="function-details" key={i}>
                    <span className="function-name">
                      <Zap />
                      <Link
                        to={`${f.package.url}/${f.filename}`}
                        target={`_blank`}
                        >
                        {f.package.name}/{f.package.version}<br />
                        {f.filename}
                      </Link>
                    </span>
                    <pre>{args}</pre>
                    <pre>{result}</pre>
                  </div>
                )
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};